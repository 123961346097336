import '../../styles/pages/fall-winter-2020/fall-winter-2020-index.scss'
import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet";
import Link from "gatsby-plugin-transition-link/AniLink";
import AnchorLink from 'react-anchor-link-smooth-scroll'
import {fall2020stories} from '../../components/fall-winter-2020-stories/stories';

import ArrowRightCircle from '../../images/icons/arrow-right-circle.svg'
import ArrowDownCircle from '../../images/icons/arrow-down-circle.svg'

import ITAT from '../../images/spring-2020/campaign-logo-horiz-with-tag-white.svg'
import CSUFNews from '../../images/fall-winter-2020/csuf-news.svg'

import socialBanner from "../../images/fall-winter-2020/social-banners/main.jpg";


export default class Index extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var pageTitle = 'Titan: Fall/Winter 2021';
    var pageDesc = 'TITAN, the signature magazine of Cal State Fullerton, is proud to premiere its first fully digital issue. Here you will find bold and inspiring stories about the university’s people and programs — stories that will keep you connected to your CSUF family, demonstrate the impact of your support and make you feel proud to be a Titan.';

    // var heroStyles = {
    //   backgroundColor: '#222',
    //   backgroundImage: `url(${cover})`,
    //   backgroundRepeat: 'no-repeat',
    //   backgroundPosition: 'center center',
    //   backgroundSize: 'cover',
    //   color: 'white',
    //   height: '1200px'
    // }

    const list = fall2020stories.map(function(story){
      if (story.slug != 'a-virus-hits-home' && story.slug != 'presidents-message') {

        var url = "/fall-winter-2020/" + story.slug + "/";
        var classname = "story-link " + story.storyType + " " + story.slug;

        var linkStyle = {
          backgroundImage: `url(${story.thumbnailIndex})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
        }

        var title = <h3>{story.title}</h3>

        if (story.slug == 'boundless-opportunity') {
          title = <h3><span className="first-word">Boundless</span> <span className="second-word">Opportunity</span></h3>
        }

        var subtitle = null;
        if (story.subtitle && story.subtitle != '') {
          subtitle = <span className="subtitle">{story.subtitle}</span>
        }


        return (
          <Link
          style={linkStyle}
          className={classname}
          paintDrip
          hex="#FF7900"
          duration={.75}
          to={url}>
            <div className="story-text">
              {title}
              {subtitle}
            </div>
          </Link>
        )
      }
    })

    return (
      <Layout locationInfo={this.props.location} issue="fall-winter-2020">
        <Helmet>
          <body className="fall-winter-2020-index" />


          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />

        </Helmet>
        <h1 style={{display: 'none'}}>Titan: Fall 2020</h1>

        <div className="full-width hero animated fadeIn slower">
          <div className="hero-text">
            <h2 className="animated fadeInDown">Face-to-Face with COVID-19</h2>
            <p className="sub-heading animated fadeInDown delay-1s">How the pandemic strengthened one student’s call to nursing</p>
            <Link
            className="button2 animated fadeInDown delay-2s"
            paintDrip
            hex="#FF7900"
            duration={.75}
            to="/fall-winter-2020/a-virus-hits-home/">
              Read Story <img src={ArrowRightCircle} alt="" className="icon" />
            </Link>
            <AnchorLink href='#intro' className="button2 more-stories-button animated fadeInDown delay-2s">
              More Stories <img src={ArrowDownCircle} alt="" className="icon" />
            </AnchorLink>
          </div>
          <div className="tint" />
        </div>

        <section id="intro">
          <h2 className="center"><em>In This Issue:</em></h2>

          <div className="wrap story-list">

            {list}

          </div>
        </section>

        
          <section className="external">
            <a href="https://campaign.fullerton.edu/" target="_blank" className="itat external-block">
              <h3><img src={ITAT} alt="It Takes a Titan - he Campaign for Cal State Fullerton" style={{width: '400px'}} /></h3>
              {/* <h3>It Takes a Titan</h3> */}
              {/* <p className="sub-head">The Campaign for Cal State Fullerton</p> */}
              <p>The university’s first-ever comprehensive philanthropic campaign aims to elevate the potential of Titan students. The $200 million initiative prioritizes investing in projects that enhance academic innovation, empower students, transform campus structures and enrich the community.</p>
              <img src={ArrowRightCircle} alt="" className="arrow-right-circle" />
            </a>
            <a href="http://news.fullerton.edu/" target="_blank" className="titan-voices external-block">
              <h3><img src={CSUFNews} alt="Titan Voices" style={{width: '250px'}}  /></h3>
              <p>Stay up to date on all the latest campus news by visiting the CSUF News website. Submit story ideas to newssubmissions@fullerton.edu.</p>
              <img src={ArrowRightCircle} alt="" className="arrow-right-circle" />
            </a>
          </section>
      </Layout>
    );
  }
}